import { RichText, Asset } from '@graphcommerce/graphcms-ui'
import { breakpointVal, SpecialBanner } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { Button, Link } from '@mui/material'
import PageLink from 'next/link'
import { RowSpecialBannerFragment } from './RowSpecialBanner.gql'

type RowSpecialBannerProps = RowSpecialBannerFragment

export function RowSpecialBanner(props: RowSpecialBannerProps) {
  const { copy, asset, topic, pageLinks, description } = props

  return (
    <SpecialBanner
      topic={topic}
      asset={asset && <Asset asset={asset} sizes='50vw' />}
      pageLinks={pageLinks.map((pageLink) => (
        <Link href={pageLink.url} key={pageLink.url}>
          <Button variant='pill' color='primary'>
            {pageLink.title}
          </Button>
        </Link>
      ))}
    >
      <div
        style={{
          marginTop: '-13vh',
        }}
      >
        <RichText
          {...copy}
          sxRenderer={{
            'heading-two': (theme) => ({
              textTransform: 'uppercase' as const,
              color: 'text.primary',
              ...breakpointVal('fontSize', 36, 82, theme.breakpoints.values),
              marginBottom: 0,
              lineHeight: 0.8,
              '& strong': {
                // https://github.com/rsms/inter/issues/292#issuecomment-674993644
                fontSize: 48,
                color: 'background.default',
                textShadow: `1.2px 0 0 ${theme.palette.text.primary},0 1.2px 0 ${theme.palette.text.primary},-1.2px 0 0 ${theme.palette.text.primary},0 -1.2px 0 ${theme.palette.text.primary}`,
              },
            }),
          }}
        />
        <p>{description}</p>
      </div>
    </SpecialBanner>
  )
}
