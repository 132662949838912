import { RichText } from '@graphcommerce/graphcms-ui'
import { breakpointVal, HeroBanner } from '@graphcommerce/next-ui'
import { useScrollY } from '@graphcommerce/next-ui/Layout/hooks/useScrollY'
import { Row } from '@graphcommerce/next-ui/Row/Row'
import { extendableComponent } from '@graphcommerce/next-ui/Styles'
import { responsiveVal } from '@graphcommerce/next-ui/Styles/responsiveVal'
import { Box, Button, Link, styled, useMediaQuery, useTheme } from '@mui/material'
import { m, useTransform } from 'framer-motion'
import Image from 'next/image'
import PageLink from 'next/link'
import React from 'react'
import { RowHeroBannerFragment } from './RowHeroBanner.gql'

const MotionDiv = styled(m.div)({})

const compName = 'HeroBanner' as const
const parts = ['root', 'wrapper', 'copy', 'asset', 'animated', 'video'] as const
const { classes } = extendableComponent(compName, parts)

export function RowHeroBanner(props: RowHeroBannerFragment) {
  const { copy, heroAsset, pageLinks } = props

  const parsedPageLinks = pageLinks.map((pageLink) => (
    <Box>
      <Link key={pageLink.url} href={pageLink.url}>
        <Button
          variant='pill'
          size='large'
          color='primary'
          sx={{
            color: 'white',
          }}
        >
          {pageLink.title}
        </Button>
      </Link>
    </Box>
  ))

  const videoSrc = heroAsset.url

  const t = useTheme()
  const matches = useMediaQuery(t.breakpoints.down('md'))
  const scrollY = useScrollY()
  const width = useTransform(
    scrollY,
    [10, 150],
    [`calc(100% - ${responsiveVal(20, 60)}))`, `calc(100% - ${responsiveVal(0, 0)})`],
  )
  const borderRadius = useTransform(
    scrollY,
    [10, 150],
    [`${responsiveVal(8, 12)}`, `${responsiveVal(0, 0)}`],
  )

  return (
    <Row
      maxWidth={false}
      disableGutters
      className={classes.root}
      sx={(theme) => ({
        paddingTop: theme.appShell.headerHeightSm,
        marginBottom: 0,
      })}
    >
      <Box className={classes.wrapper} sx={{ position: 'relative' }}>
        <Box
          className={classes.copy}
          sx={(theme) => ({
            zIndex: 1,
            color: theme.palette.secondary.contrastText,
            position: 'relative',
            display: 'grid',
            justifyItems: 'left',
            alignContent: 'center',
            lineHeight: 0.8,
            padding: `${theme.spacings.lg} ${theme.spacings.md}`,
            paddingTop: `calc(${theme.spacings.lg} - ${theme.spacings.md})`,
            minHeight: `calc(60vh - ${theme.appShell.headerHeightSm})`,
            '& > *': {
              zIndex: 1,
              maxWidth: 'max-content',
            },
            [theme.breakpoints.up('md')]: {
              width: '100%',
              minHeight: `calc(68vh - ${theme.appShell.headerHeightMd})`,
            },
            [theme.breakpoints.up('lg')]: {
              padding: `${theme.spacings.lg} ${theme.spacings.lg}`,
              paddingTop: `calc(${theme.spacings.lg} - ${theme.spacings.md})`,
              width: '80%',
            },
          })}
        >
          <RichText
            {...copy}
            sxRenderer={{
              'heading-one': (theme) => ({
                maxWidth: '80%',
                textAlign: 'center' as const,
                margin: 0,
                marginBottom: 1,
                ...breakpointVal('fontSize', 36, 82, theme.breakpoints.values),
                [theme.breakpoints.up('md')]: {
                  textAlign: 'left',
                  maxWidth: '100%',
                },
                '& strong': {
                  WebkitTextFillColor: 'transparent',
                  WebkitTextStroke: `1.2px #fff`,
                },
              }),
            }}
          />
          {parsedPageLinks}
        </Box>
        <Box
          className={classes.asset}
          sx={(theme) => ({
            position: 'absolute',
            top: '0',
            zIndex: 0,
            width: '100%',
            height: '100%',
            display: 'grid',
            justifyItems: 'center',
            overflow: 'hidden',
            paddingBottom: theme.page.horizontal,
            '& img': {
              objectFit: 'cover',
              width: '100%',
              height: '100%',
              [theme.breakpoints.down('md')]: {
                ...breakpointVal(
                  'borderRadius',
                  theme.shape.borderRadius * 2,
                  theme.shape.borderRadius * 3,
                  theme.breakpoints.values,
                ),
              },
            },
            [theme.breakpoints.up('md')]: {
              height: '100%',
            },
          })}
        >
          <MotionDiv
            style={{ width, borderRadius }}
            className={classes.animated}
            sx={(theme) => ({
              ...breakpointVal(
                'borderRadius',
                theme.shape.borderRadius * 2,
                theme.shape.borderRadius * 3,
                theme.breakpoints.values,
              ),
              overflow: 'hidden',
              transform: 'translateZ(0)',
            })}
          >
            <Image
              src={videoSrc}
              className={classes.video}
              layout='fill'
              objectFit='cover'
              objectPosition='center'
              alt='Hero Banner'
            />
          </MotionDiv>
        </Box>
      </Box>
    </Row>
  )
}
