import { AmbientFragment } from './Ambient.gql'
import { Grid, Typography } from '@mui/material'
import Link from 'next/link'
import { width } from '@mui/system'


export function Ambient(props: AmbientFragment) {
  return (
    <div style={{ margin: '0 auto', maxWidth: '90%', padding: '16px', marginBottom: '100px' }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={6}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} md={6}>
              <Link href='/elenco-ambienti/banconi-reception'>
                <div
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    height: '100%',
                    borderRadius: '8px',
                  }}
                >
                  <img
                    src='/img/reception.png'
                    alt='Image 1'
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      transition: 'transform 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      padding: '8px',
                    }}
                  >
                    <Typography
                      variant='h3'
                      style={{
                        color: '#fff',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      Reception
                    </Typography>
                  </div>
                </div>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Link href='/elenco-ambienti/arredi-lounge'>
                <div
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    height: '100%',
                    borderRadius: '8px',
                  }}
                >
                  <img
                    src='/img/Lounge.jpg'
                    alt='Image 1'
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      transition: 'transform 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      padding: '8px',
                    }}
                  >
                    <Typography
                      variant='h3'
                      style={{
                        color: '#fff',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      Lounge
                    </Typography>
                  </div>
                </div>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Link href='/elenco-ambienti/illuminazione-lampade'>
                <div
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    height: '100%',
                    borderRadius: '8px',
                  }}
                >
                  <img
                    src='/img/Illuminazione.png'
                    alt='Image 1'
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      transition: 'transform 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      padding: '8px',
                    }}
                  >
                    <Typography
                      variant='h3'
                      style={{
                        color: '#fff',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      Illuminazione
                    </Typography>
                  </div>
                </div>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Link href='/elenco-ambienti/complementi-di-arredo'>
                <div
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    height: '100%',
                    borderRadius: '8px',
                  }}
                >
                  <img
                    src='/img/Complementi_darredo.jpg'
                    alt='Image 1'
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      transition: 'transform 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      padding: '8px',
                    }}
                  >
                    <Typography
                      variant='h3'
                      style={{
                        color: '#fff',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      Complementi di arredo
                    </Typography>
                  </div>
                </div>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Grid container>
            <Grid item xs={12}>
              <Link href='/elenco-ambienti/arredi-bar-ristoranti'>
                <div
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    height: '102%',
                    borderRadius: '8px',
                  }}
                >
                  <img
                    src='/img/bar.png'
                    alt='Image 1'
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      transition: 'transform 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      padding: '8px',
                    }}
                  >
                    <Typography
                      variant='h2'
                      style={{
                        color: '#fff',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      Bar e ristoranti
                    </Typography>
                  </div>
                </div>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}