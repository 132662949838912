import { iconPhone, iconCustomerService, IconSvg } from '@graphcommerce/next-ui'
import iconTransport from '@graphcommerce/next-ui/icons/transport.svg'
import iconVerified from '@graphcommerce/next-ui/icons/verified.svg'
import { Box, Typography, Grid } from '@mui/material'

import Link from 'next/link'
import svgDelivery from '../Icons/jode_delivery.svg'

export function Feature() {
  return (
    <Grid container spacing={4} bgcolor='#662b57' p={4} sx={{ margin: '1rem 0', width: '100%' }}>
      <Grid
        item
        xs={12}
        sm={6}
        lg={3}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box>
          <Link href='/about/conditions'>
            <IconSvg
              src={iconTransport}
              size='large'
              style={{
                fontSize: 48,
                color: 'white',
                marginBottom: 0,
              }}
            />
          </Link>
        </Box>
        <Box>
          <Link href='/condizioni' style={{ textDecoration: 'none' }}>
            <Typography variant='h3' align='center' color='white'>
              Spedizione Gratuita
            </Typography>
          </Link>
        </Box>
        <Box>
          <Link href='/condizioni' style={{ textDecoration: 'none' }}>
            <Typography variant='body1' align='center' color='white'>
              Vedi le condizioni
            </Typography>
          </Link>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        lg={3}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box>
          <Link href='tel:800603309'>
            <IconSvg
              src={iconPhone}
              size='large'
              style={{
                fontSize: 48,
                color: 'white',
                marginBottom: 3,
              }}
            />
          </Link>
        </Box>
        <Box>
          <Link href='tel:800603309' style={{ textDecoration: 'none' }}>
            <Typography variant='h3' align='center' color='white'>
              Numero Verde
            </Typography>
          </Link>
        </Box>
        <Box>
          <Link href='tel:800603309' style={{ textDecoration: 'none' }}>
            <img src='/Icons/numero_verde_bianco.svg' width='auto' height={60} alt='800 603 309' />
          </Link>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        lg={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box>
          <IconSvg
            src={iconCustomerService}
            size='large'
            style={{
              fontSize: 48,
              color: 'white',
              marginBottom: 3,
            }}
          />
        </Box>
        <Box>
          <Typography variant='h3' align='center' color='white'>
            Parla con un nostro operatore
          </Typography>
        </Box>
        <Box>
          <Typography variant='body1' align='center' color='white'>
            Clicca l’icona in basso a destra per iniziare a parlare con un nostro operatore
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        lg={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box>
          <Link href='/pdf/iso-9001.pdf' target='_blank'>
            <IconSvg
              src={iconVerified}
              size='large'
              style={{
                fontSize: 48,
                color: 'white',
                marginBottom: 5,
              }}
            />
          </Link>
        </Box>
        <Link href='/pdf/iso-9001.pdf' style={{ textDecoration: 'none' }} target='_blank'>
          <Box>
            <Typography variant='h3' align='center' color='white'>
              Qualità Certificata
            </Typography>
          </Box>
          <Box>
            <Typography variant='body1' align='center' color='white'>
              SINCERT UNI EN ISO 9001
            </Typography>
          </Box>
        </Link>
      </Grid>
    </Grid>
  )
}
