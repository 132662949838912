import { RichText } from '@graphcommerce/graphcms-ui'
import { Grid, Typography } from '@mui/material'
import Image from 'next/image'
import Link from 'next/link'
import { CategoriesMasonryFragment } from './CategoriesMasonry.gql'

export function CategoriesMasonry(props: CategoriesMasonryFragment) {
  const { linkedCategoryBig, linkedCategories, header } = props

  return (
    <div style={{ textAlign: 'center', marginTop: '2rem' }}>
      <div style={{ width: '90%', maxWidth: '500px', margin: '0 auto', marginBottom: '1rem' }}>
        {header?.raw && (
          <RichText
            {...header}
            sxRenderer={{
              paragraph: {
                textAlign: 'center' as const,
              },
              'heading-one': (theme) => ({
                color: theme.palette.primary.main,
              }),
            }}
          />
        )}
      </div>

      <div style={{ margin: '0 auto', maxWidth: '90%', padding: '16px', marginBottom: '100px' }}>
        <Grid container spacing={4}>
          {linkedCategoryBig && (
            <Grid item xs={12} sm={6} md={6}>
              <Grid container height='100%'>
                <Grid item xs={12} height='100%'>
                  <Link href={linkedCategoryBig.url} style={{ width: '100%', height: '100%' }}>
                    <div
                      style={{
                        position: 'relative',
                        overflow: 'hidden',
                        width: '100%',
                        height: '100%',
                        borderRadius: '8px',
                      }}
                    >
                      <Image
                        src={linkedCategoryBig?.image?.url}
                        alt='Image 1'
                        style={{ objectFit: 'cover' }}
                        fill
                      />
                      <div
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          right: 0,
                          padding: '8px',
                        }}
                      >
                        <Typography
                          variant='h2'
                          style={{
                            color: '#fff',
                            fontWeight: 'bold',
                            textAlign: 'center',
                          }}
                        >
                          {linkedCategoryBig?.title}
                        </Typography>
                      </div>
                    </div>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={6}>
            <Grid container spacing={5}>
              {linkedCategories?.map((category) => (
                <Grid item xs={12} sm={6} md={6} height='25rem' width='100%'>
                  <Link href={category?.url} style={{ width: '100%', height: '100%' }}>
                    <div
                      style={{
                        position: 'relative',
                        overflow: 'hidden',
                        height: '100%',
                        width: '100%',
                        borderRadius: '8px',
                      }}
                    >
                      <Image
                        src={category?.image?.url}
                        alt='Image 1'
                        fill
                        style={{ objectFit: 'cover' }}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          right: 0,
                          padding: '8px',
                        }}
                      >
                        <Typography
                          variant='h3'
                          style={{
                            color: '#fff',
                            fontWeight: 'bold',
                            textAlign: 'center',
                          }}
                        >
                          {category?.title}
                        </Typography>
                      </div>
                    </div>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
