import { ContentLinks } from '@graphcommerce/next-ui'
import { Box, Link } from '@mui/material'
import PageLink from 'next/link'
import { RowContentLinksFragment } from './RowContentLinks.gql'

export function RowContentLinks(props: RowContentLinksFragment) {
  const { title, contentLinks } = props

  return (
    <div
      style={{
        backgroundColor: '#662B58',
        color: 'white',
        paddingTop: '2rem',
        paddingBottom: '20px',
      }}
    >
      <ContentLinks
        title='Su Jode :'
        sx={{
          marginBottom: '21px',
          '& h2': {
            fontWeight: 'bold',
          },
        }}
      >
        <Link
          href='/about'
          variant='body1'
          color='inherit'
          style={{
            paddingLeft: '49px',
          }}
        >
          Azienda
        </Link>

        <Link href='/sprivacy-policy' variant='body1' color='inherit'>
          Privacy
        </Link>

        <Link href='/contact' variant='body1' color='inherit'>
          Contatti
        </Link>

        <Link href='/condizioni' variant='body1' color='inherit'>
          Condizioni di acquisto
        </Link>
      </ContentLinks>
      <ContentLinks
        title='Servizio clienti :'
        sx={{
          marginBottom: '21px',
          '& h2': {
            fontWeight: 'bold',
          },
        }}
      >
        <Link href='/service/order' variant='body1' color='inherit'>
          Ordini
        </Link>

        <Link href='/service/shipping' variant='body1' color='inherit'>
          Opzioni di spedizione
        </Link>

        <Link href='/service/payment-information' variant='body1' color='inherit'>
          Pagamenti
        </Link>

        <Link href='/service/return' variant='body1' color='inherit'>
          Scambi e resi
        </Link>
      </ContentLinks>
      <ContentLinks
        title='Il mio account :'
        sx={{
          marginBottom: '21px',
          '& h2': {
            fontWeight: 'bold',
          },
        }}
      >
        <Link
          href='/account/signin'
          variant='body1'
          color='inherit'
          style={{
            paddingLeft: '3px',
          }}
        >
          Account
        </Link>

        <Link href='/wishlist' variant='body1' color='inherit'>
          Wishlist
        </Link>
      </ContentLinks>
    </div>
  )
}
