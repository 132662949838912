import { Grid, Typography } from '@mui/material'
import Link from 'next/link'

export function AmbientWithTitle() {
  return (
    <div style={{ textAlign: 'center', marginTop: '150px' }}>
      <Typography variant='h2' align='center'>
        Hotel, Residence, B&B
      </Typography>
      <div style={{ width: '90%', maxWidth: '500px', margin: '0 auto', marginBottom: '50px' }}>
        <Typography variant='body1' align='center'>
          La più vasta gamma di mobili per l’arredamento di alberghi, B&B, agriturismi, bar e
          ristoranti; ma anche mense, circoli e caffetteria.
        </Typography>
      </div>

      <div style={{ margin: '0 auto', maxWidth: '90%', padding: '16px', marginBottom: '100px' }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <Link href='/elenco-ambienti/collezioni-camere'>
                  <div
                    style={{
                      position: 'relative',
                      overflow: 'hidden',
                      height: '102%',
                      borderRadius: '8px',
                    }}
                  >
                    <img
                      src='/img/Camere_collezioni.png'
                      alt='Image 1'
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        transition: 'transform 0.2s ease-in-out',
                        '&:hover': {
                          transform: 'scale(1.05)',
                        },
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: '8px',
                      }}
                    >
                      <Typography
                        variant='h2'
                        style={{
                          color: '#fff',
                          fontWeight: 'bold',
                          textAlign: 'center',
                        }}
                      >
                        Camere Collezioni
                      </Typography>
                    </div>
                  </div>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} md={6}>
                <Link href='/elenco-ambienti/arredi-camere-moderne-classiche'>
                  <div
                    style={{
                      position: 'relative',
                      overflow: 'hidden',
                      height: '100%',
                      borderRadius: '8px',
                    }}
                  >
                    <img
                      src='/img/Camere_componenti.png'
                      alt='Image 1'
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        transition: 'transform 0.2s ease-in-out',
                        '&:hover': {
                          transform: 'scale(1.05)',
                        },
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: '8px',
                      }}
                    >
                      <Typography
                        variant='h3'
                        style={{
                          color: '#fff',
                          fontWeight: 'bold',
                          textAlign: 'center',
                        }}
                      >
                        Camere Componenti
                      </Typography>
                    </div>
                  </div>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Link href='/camere-complete'>
                  <div
                    style={{
                      position: 'relative',
                      overflow: 'hidden',
                      height: '100%',
                      borderRadius: '8px',
                    }}
                  >
                    <img
                      src='/img/Camere_complete.jpg'
                      alt='Image 1'
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        transition: 'transform 0.2s ease-in-out',
                        '&:hover': {
                          transform: 'scale(1.05)',
                        },
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: '8px',
                      }}
                    >
                      <Typography
                        variant='h3'
                        style={{
                          color: '#fff',
                          fontWeight: 'bold',
                          textAlign: 'center',
                        }}
                      >
                        Camere Complete
                      </Typography>
                    </div>
                  </div>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Link href='/elenco-ambienti/arredi-ignifughi'>
                  <div
                    style={{
                      position: 'relative',
                      overflow: 'hidden',
                      height: '100%',
                      borderRadius: '8px',
                    }}
                  >
                    <img
                      src='/img/arredi-ignifughi.png'
                      alt='Image 1'
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        transition: 'transform 0.2s ease-in-out',
                        '&:hover': {
                          transform: 'scale(1.05)',
                        },
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: '8px',
                      }}
                    >
                      <Typography
                        variant='h3'
                        style={{
                          color: '#fff',
                          fontWeight: 'bold',
                          textAlign: 'center',
                        }}
                      >
                        Arredi Ignifughi
                      </Typography>
                    </div>
                  </div>
                </Link>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Link href='/elenco-ambienti/home-office'>
                  <div
                    style={{
                      position: 'relative',
                      overflow: 'hidden',
                      height: '100%',
                      borderRadius: '8px',
                    }}
                  >
                    <img
                      src='/img/Ufficio.jpeg'
                      alt='Image 1'
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        transition: 'transform 0.2s ease-in-out',
                        '&:hover': {
                          transform: 'scale(1.05)',
                        },
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: '8px',
                      }}
                    >
                      <Typography
                        variant='h3'
                        style={{
                          color: '#fff',
                          fontWeight: 'bold',
                          textAlign: 'center',
                        }}
                      >
                        Ufficio
                      </Typography>
                    </div>
                  </div>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
